<template>
  <div>
    <div v-if="this.$route.name === 'SupplierPayment'">
      <SupplierPayment />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import SupplierPayment from "@/views/companyApp/supplierPayment/SupplierPayment.vue";

export default {
  name: "SupplierPaymentParent",
  components: {
    SupplierPayment,
  },
};
</script>
